import React from 'react'
import { Link } from 'gatsby-plugin-react-i18next'

import './button.scss'

export default function Button({ to, children, className, href, style }) {

    const El = href ? 'a' : 'button'

    const PROPS = {
        a: {
            href
        },
        button: {
            type: 'button'
        }
    }

    if (to) return (
        <Link className='button' to={to}>{ children }</Link>
    )

    return (
        <El {...PROPS[El]} className={`button ${ className ? className : '' }`} style={style}>
            { children }
        </El>
    )
}

export function PrimaryButton({ ...rest }) {
    return <Button {...rest} className='button--primary' />
}