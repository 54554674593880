import React from "react"

import Title from 'components/Title'
import Constrain from 'components/Constrain'
import Video from 'components/Video'

import { PrimaryButton } from 'components/Button'

import image from 'images/shop-management-video.png'

import { useSiteMetadata } from '../hooks/useSiteMetadata'

// import Container from 'components/Container'
import Footer from "components/Footer"
import Header from "components/Header"

import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function Home() {

  const { t } = useTranslation()

  const { appUrl } = useSiteMetadata()

  return (
    <>
      <Header/>
      <main style={{ textAlign: 'center' }}>
        <Title title={ t(`Effortless shop managment`) } subtitle={ t(`Massive time-savings through automated customer communication.`) } dark />
        <Constrain>
          <div style={{
            marginBottom: '4rem',
            marginTop: '-6rem'
          }}>
            <PrimaryButton href={ appUrl + '/sign-up' }>{ t(`Sign up now`) }</PrimaryButton>
          </div>
          {/* <Screenshot src='https://cdn.tueri.io/274877907217/schedule.png' alt='Scheduling overview' /> */}

          <Video poster={ image } src='https://autoservice.nyc3.cdn.digitaloceanspaces.com/media/shop-management-video.mp4' />
        </Constrain>

        {/* <Container>

        <h1>{ t('signUp', 'Sign up') }</h1>

        </Container> */}
    </main>
    <Footer />
  </>
  )
}
